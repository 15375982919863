import request from '@/utils/request'

// 用户列表
export const getMemberList = (params) => {
    return request.get('admin/memberList', { params })
}

// 用户详情
export const getMemberDetail = (params) => {
    return request.get('admin/memberDetail', { params })
}

// 用户添加/修改
export const updateMember = (params) => {
    return request.post('admin/memberDetail', params)
}
