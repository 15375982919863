<!-- 用户 -->
<template>
  <!-- 弹窗 -->
  <Dialog :data="dialog" @submit="submit">
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <!-- <el-form-item label="头像:">
        <el-upload
          class="avatar-uploader"
          :action="uploadurl"
          :headers="token"
          :on-success="onSuccess"
          :show-file-list="false"
        >
          <img v-if="formData.u_avatar" :src="formData.u_avatar" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon" />
        </el-upload>
      </el-form-item> -->
      <el-form-item label="真实姓名:" prop="u_realname"> <el-input v-model="formData.u_realname" /></el-form-item>
      <el-form-item label="职称:" prop="u_titles"> <el-input v-model="formData.u_titles" /></el-form-item>
      <el-form-item label="昵称:" prop="u_nickname"> <el-input v-model="formData.u_nickname" /></el-form-item>
      <el-form-item label="手机号:" prop="u_mobile"> <el-input v-model="formData.u_mobile" /></el-form-item>
      <el-form-item label="密码:" prop="u_password"> <el-input v-model="formData.u_password" /></el-form-item>
      <el-form-item label="用户状态:" prop="u_status">
        <el-radio v-model="formData.u_status" checked class="mr15" :label="1">正常</el-radio>
        <el-radio v-model="formData.u_status" class="mr15" :label="2">禁用</el-radio>
        <el-radio v-model="formData.u_status" class="mr15" :label="3">拉黑</el-radio>
      </el-form-item>
      <el-form-item label="权限菜单:" prop="u_nodes">
        <el-checkbox-group v-model="formData.u_nodes">
          <el-checkbox v-for="item in nodeData" :key="item.id" :label="item.id" class="mr15">{{ item.type_name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </Dialog>
</template>

<script>
export default {
    name: 'MemberView',
    props: {
        formData: { type: Object, default: () => {} },
        nodeData: { type: Array, default: () => [] }
    },
    data() {
        return {
            uploadurl: process.env.VUE_APP_BASE_API + 'admin/localUpload',
            rules: {
                u_realname: [
                    { required: true, trigger: 'blur', message: '请输入名称！' }
                ]
            },
            dialog: {
                sign: '',
                title: '',
                switch: false,
                open: () => {}
            }
        }
    },
    computed: {
        token() { return { 'Authorization': 'Bearer ' + this.$store.getters.token } }
    },
    methods: {
        onSuccess(res, file) {
            if (res.code === 0) {
                this.formData.u_avatar = res.data.path
            }
        },
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$emit('submit', { formData: this.formData, sign: this.dialog.sign })
                } else {
                    return false
                }
            })
        },
        resetFields() {
            this.formData.u_realname = ''
            this.formData.u_nickname = ''
            this.formData.u_mobile = ''
            this.formData.u_password = ''
            this.formData.u_status = ''
            this.formData.u_nodes = []

            this.$refs.form.resetFields()
        },
        openDialog(config = { title: '', sign: '', switch: '' }) {
            this.dialog.title = config.title
            this.dialog.sign = config.sign
            this.dialog.switch = config.switch
        }
    }
}
</script>

<style lang='scss' scoped>
::v-deep .el-form{
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    .avatar {
        width: 100px;
        height: 100px;
        display: block;
    }
}
</style>
