<!-- 用户中心 -->
<template>
  <Container>
    <template #header>
      <el-button-group>
        <el-button type="primary" @click="onAdd">新增</el-button>
      </el-button-group>
    </template>
    <Table :head-config="listConfig.headConfig" :load-data="listConfig.dataList">
      <template v-slot:u_avatar="scope">
        <el-image style="width: 60px; height: 60px" :src="scope.data.row.u_avatar" />
      </template>
      <template v-slot:u_nodes="scope">
        <div v-for="(item,index) in scope.data.row.nodes_arr" :key="index">
          <el-tag size="small">{{ item }}</el-tag>
        </div>
      </template>
      <template v-slot:u_status="scope">
        <el-tag v-if="scope.data.row.u_status === 1" size="small" type="success">正常</el-tag>
        <el-tag v-else-if="scope.data.row.u_status === 2" size="small" type="info">禁用</el-tag>
        <el-tag v-else-if="scope.data.row.u_status === 3" size="small" type="danger">拉黑</el-tag>
      </template>
      <template v-slot:handle="scope">
        <el-button type="primary" size="mini" @click="edit(scope.data)">编辑</el-button>
        <!-- <el-button type="danger" size="mini" @click="del(scope.data)">删除</el-button> -->
      </template>
    </Table>

    <MemberView ref="memberView" :form-data.sync="form" :node-data.sync="fileNodesArr" @submit="submit" />
  </Container>
</template>

<script>
import MemberView from './components/MemberView'
import { getMemberList, updateMember, getMemberDetail } from '@/api/member'
import { getFileTypelist } from '@/api/file'
export default {
    components: { MemberView },
    data() {
        return {
            listConfig: {
                headConfig: [ // 表头数据
                    { label: '头像', columnType: 'slot', slotName: 'u_avatar' },
                    { label: '真实姓名', field: 'u_realname' },
                    { label: '昵称', field: 'u_nickname' },
                    { label: '手机号', field: 'u_mobile' },
                    { label: '用户状态', columnType: 'slot', slotName: 'u_status' },
                    { label: '权限菜单', columnType: 'slot', slotName: 'u_nodes' },
                    { label: '操作', columnType: 'slot', slotName: 'handle', width: 180 }
                ],
                dataList: [], // table数据
                pageData: { total: 0, pageIndex: 1, pageSize: 10 } // 分页数据
            },
            form: {
                u_realname: '',
                u_nickname: '',
                u_password: '',
                u_mobile: '',
                u_status: '',
                u_nodes: []
            },
            fileNodesArr: [] // 文件分类列表
        }
    },
    computed: { },
    watch: {},
    created() {
        this.getList()
    },
    mounted() { },
    methods: {
        getList() {
            getMemberList().then(res => {
                if (res.code === 0) {
                    this.listConfig.dataList = res.list
                }
            })
            getFileTypelist().then(res => {
                if (res.code === 0) {
                    this.fileNodesArr = res.list
                }
            })
        },
        onAdd() {
            this.$refs.memberView.openDialog({ sign: 'add', title: '新增', switch: true })
        },
        edit(e) {
            getMemberDetail({ u_id: e.row.u_id }).then(res => {
                if (res.code === 0) {
                    this.form = res.list
                    if (this.form.u_nodes) {
                        this.form.u_nodes = res.list.u_nodes.split(',').map(function(item) {
                            return Number(item)
                        })
                    } else {
                        this.form.u_nodes = []
                    }
                    this.$refs.memberView.openDialog({ sign: 'edit', title: '编辑', switch: true })
                }
            })
        },
        submit(val) {
            if (val.formData.u_nodes.length === 0) {
                val.formData.u_nodes = []
            }
            updateMember(val.formData).then(res => {
                if (res.code === 0) {
                    this.$refs.memberView.resetFields()
                    this.$refs.memberView.dialog.switch = false
                    this.getList()
                }
            })
        }
    }
}
</script>

